import {
  ReplyNewClientType,
  ReplyType,
  VacancyType,
} from "graphql/types/types";
import { DisabledReplies } from "services/store/modules/repliesList/types";
import { IUserByID } from "services/store/modules/user/types";
import { DisabledVacancies } from "services/store/modules/vacanciesList/types";

type Props = {
  id: ReplyType["id"] | VacancyType["id"] | ReplyNewClientType["id"];
  disabledList: DisabledVacancies | DisabledReplies;
  ownerId: IUserByID["ID"];
};
export const getTableRowDisabled = ({ id, disabledList, ownerId }: Props) => {
  const disabledListById = disabledList[id];

  return disabledListById ? disabledListById.ownerId !== ownerId : false;
};
