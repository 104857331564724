import React from "react";
import {
  Control,
  Controller,
  UseFormSetError,
  UseFormClearErrors,
  FieldErrors,
} from "react-hook-form";

import {
  CallCenterFiltersState,
  MangoFiltersState,
  PilotsFiltersState,
} from "../../types";
import { FormRangePicker } from "components/UI/Form/FormRangePicker";

type State = CallCenterFiltersState | MangoFiltersState | PilotsFiltersState;

type Props = {
  control: Control<State>;
  errors: FieldErrors<State>;
  setError: UseFormSetError<State>;
  clearErrors: UseFormClearErrors<State>;
};

export const DateController: React.FC<Props> = ({
  control,
  errors,
  setError,
  clearErrors,
}) => {
  return (
    <>
      <Controller
        name="date"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FormRangePicker
            label="Период"
            format="DD.MM.YYYY"
            picker="date"
            error={errors.date?.message || error?.message}
            value={value}
            onChange={(value) => {
              clearErrors("date");

              if (value === null) {
                setError("date", {
                  message: "Период должен быть заполнен",
                });
              }

              onChange(value);
            }}
          />
        )}
      />
    </>
  );
};
