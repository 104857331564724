import { gql } from "@apollo/client";

export const PUBLISH_VACANCY = gql`
  mutation publishVacancy($id: Int!) {
    publishVacancy(id: $id) {
      ... on VacancyType {
        id
        name
        additionalAvito {
          bonuses
          employment
          experience
          profession
          registrationMethod
          schedule
          title
          workFormat
          managerId
          whoPublished
        }
        additionalHh {
          acceptHandicapped
          acceptKids
          acceptTemporary
          area
          driverLicense
          experience
          languages
          name
          professionalRoles
        }
        address {
          block
          blockType
          city
          cityType
          house
          houseType
          lat
          lng
          region
          regionType
          settlement
          street
          streetType
          value
        }
        age {
          from
          to
        }
        callCenterInfo {
          addresses
          description
          driverLicense
          experience
          farePayment
          goodsCategory
          mobilePayment
          personalCar
          presenceOfPda
          project
          vaccination
        }
        citizenship {
          id
          key
          name
        }
        contacts {
          email
          fullName
          phone
        }
        employer {
          id
          key
          logo
          name
        }
        employment {
          id
          key
          name
        }
        extraAvito {
          publicationFinishTime
          remoteId
          remoteUrl
          message
          state
        }
        extraHh {
          publicationFinishTime
          remoteId
          remoteUrl
          message
          state
        }
        location {
          lat
          lng
        }
        manager {
          city
          companyId
          departmentId
          email
          firstName
          phone
          id
          role
          secondName
        }
        salary {
          currency
          from
          gross
          to
        }
        refusal {
          id
          key
          name
          type
        }
        tag {
          color
          colorBg
          key
          name
        }
        candidateCount
        city
        claims
        closedAt
        counterparty
        createdAt
        deletedAt
        description
        kladrId
        medicalBook
        metro {
          distance
          line
          name
        }
        peopleCount
        phonePresence
        position {
          categoryId
          id
          key
          name
          restriction
        }
        positionId
        priority
        publishedAt
        publishedOnAvito
        publishedOnHh
        publishedOnRabotut
        reason
        refusalText
        region
        relevance
        replyCount
        replyCountNew
        responsiblePerson
        route
        schedule
        shiftEnd
        shiftStart
        shortDescription
        showContacts
        status
        subdivision
        terms
        tradePoint
        updatedAt
      }
      ... on Error {
        statusCode
        errors
        message
      }
    }
  }
`;
