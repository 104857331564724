import { forwardRef, Ref } from "react";
import { Cascader as AntCascader, CascaderProps } from "antd";
import type { CascaderRef } from "antd/lib/cascader";
import cn from "classnames/dedupe";

import "./styles.scss";

export interface Option {
  value?: string | number | null;
  label: React.ReactNode;
  disabled?: boolean;
  children?: Option[];
  type?: string;
}

export type Props = CascaderProps<Option>;

export const Cascader = forwardRef<CascaderRef, Props>(
  ({ className, size = "large", ...props }, ref: Ref<CascaderRef>) => {
    return (
      <AntCascader
        ref={ref}
        className={cn(
          "ui-cascader",
          { [`ui-cascader-${size}`]: size },
          className
        )}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        size={size}
        {...props}
      />
    );
  }
);
