import React from "react";
import cn from "classnames/dedupe";

import "./styles.scss";

interface Props {
  label: string;
  className?: string;
  required?: boolean;
}

export const FormLabel: React.FC<Props> = ({ label, required, className }) => (
  <span className={cn("ui-form-label", className)}>
    <span className="ui-form-label-name">{label}</span>
    {required && <span className="ui-form-label-required">*</span>}
  </span>
);
