import React, { useCallback, useEffect } from "react";

import { useAppSelector } from "services/store/store";
import { useAppDispatch } from "services/store/store";
import { PaginationPayload } from "components/UI/Pagination";

import { RepliesNewTable } from "./components/RepliesNewTable";
import { QueryReplyNewListArgs } from "graphql/types/types";
import { PAGINATION } from "common/const/pagination";
import { getReplyNewList } from "services/store/modules/repliesNew/action";
import {
  selectRepliesNewListData,
  selectRepliesNewListLoading,
  selectRepliesNewListMeta,
} from "services/store/modules/repliesNew/selectors";

import "./styles.scss";
import { authInfo } from "../../../services/store/modules/auth";

type Props = {
  className?: string;
};

export const RepliesNew: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const replies = useAppSelector(selectRepliesNewListData);
  const meta = useAppSelector(selectRepliesNewListMeta);
  const repliesLoading = useAppSelector(selectRepliesNewListLoading);
  const userData = useAppSelector(authInfo);

  const getReplies = useCallback(
    (payload: QueryReplyNewListArgs) => {
      dispatch(getReplyNewList(payload));
    },
    [dispatch]
  );
  const handleCategoryClick = useCallback(
    (pagination: PaginationPayload = PAGINATION.REPLIES) => {
      getReplies({ pagination });
    },
    [getReplies]
  );

  useEffect(() => {
    getReplies({
      pagination: PAGINATION.REPLIES,
    });
  }, []);

  return (
    <div className="replies-container">
      {replies && meta && userData && (
        <RepliesNewTable
          replies={replies}
          meta={meta}
          loading={repliesLoading}
          onChangePagination={handleCategoryClick}
          userData={userData}
        />
      )}
    </div>
  );
};
