import { createSelector } from "reselect";
import { RootState } from "../../rootReducer";
import { PAGINATION } from "common/const/pagination";

const meta = PAGINATION.VACANCY;

export const selectCandidates = (state: RootState) => state.candidates;

export const selectCandidateList = createSelector(
  selectCandidates,
  (state) => state.list
);

export const selectCandidateListData = createSelector(
  selectCandidateList,
  (state) => state.data || []
);

export const selectCandidateListMeta = createSelector(
  selectCandidateList,
  (state) => {
    const {
      limit = meta.limit,
      offset = meta.offset,
      cursor = meta.cursor,
      total = 0,
    } = state.meta || {};

    return { limit, offset, total, cursor };
  }
);

export const selectMetaIsLastPage = createSelector(
  selectCandidateListMeta,
  ({ offset, total }) => offset >= total
);

export const selectSuitableMetaIsLastPage = createSelector(
  selectCandidateListMeta,
  ({ total }) => total === 0
);

export const selectCandidateSearch = createSelector(
  selectCandidates,
  (state) => state.search
);

export const selectCandidateSearchData = createSelector(
  selectCandidateSearch,
  (state) => state.data || []
);
