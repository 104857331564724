import React from "react";
import {
  Control,
  Controller,
  UseFormSetError,
  UseFormClearErrors,
  FieldErrors,
} from "react-hook-form";

import { FormSelect } from "components/UI/Form/FormSelect";
import { filterOption } from "components/UI/Select/helpers";
import { FormInput } from "components/UI/Form/FormInput";
import { IUser } from "services/store/modules/user/types";
import { CallCenterFiltersState } from "../../types";
import { DepartmentType } from "graphql/types/types";
import { DateController } from "../DateController";

type Props = {
  control: Control<CallCenterFiltersState>;
  departmentList: DepartmentType[];
  managerList: IUser[];
  errors: FieldErrors<CallCenterFiltersState>;
  setError: UseFormSetError<CallCenterFiltersState>;
  clearErrors: UseFormClearErrors<CallCenterFiltersState>;
};

export const CallCenterFilters: React.FC<Props> = ({
  control,
  departmentList,
  managerList,
  errors,
  setError,
  clearErrors,
}) => {
  return (
    <>
      <DateController
        control={control}
        errors={errors}
        setError={setError}
        clearErrors={clearErrors}
      />

      <Controller
        name="managerId"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="Менеджер вакансии"
            placeholder="Выберите менеджера"
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            options={managerList?.map(({ fullName, id }) => ({
              label: fullName,
              value: id,
            }))}
            {...field}
          />
        )}
      />

      <Controller
        name="departamentId"
        control={control}
        rules={{ required: false }}
        render={({ field }) => (
          <FormSelect
            label={"Департамент"}
            placeholder="Выберите департамент"
            options={departmentList.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
            {...field}
          />
        )}
      />

      <Controller
        name="projectName"
        control={control}
        render={({ field }) => (
          <FormInput
            label={"Проект"}
            placeholder="Выберите проект"
            {...field}
          />
        )}
      />
    </>
  );
};
