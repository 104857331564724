import React from "react";
import { COLOR_BY_VACANCY_STATUS } from "../../common/const/colors";
import { VACANCY_STATUS_TYPE } from "../../common/const/status";
import cn from "classnames";

type VacancyStatusColor = keyof typeof COLOR_BY_VACANCY_STATUS;

interface Props {
  status: VacancyStatusColor;
  hasBorder?: boolean;
  className?: string;
}

export const VacancyStatus: React.FC<Props> = ({
  status,
  hasBorder = false,
  className = "",
}) => {
  const style: React.CSSProperties = {
    color: COLOR_BY_VACANCY_STATUS[status],
    ...(hasBorder && { borderColor: COLOR_BY_VACANCY_STATUS[status] }),
  };
  return (
    <div
      className={cn("vacancy-status-indicator", className)}
      style={style}
      title={VACANCY_STATUS_TYPE[status]}
    >
      {VACANCY_STATUS_TYPE[status]}
    </div>
  );
};
