import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Moment } from "moment";

import { useAppDispatch, useAppSelector } from "services/store/store";
import {
  selectCandidateHistory,
  selectCandidateMainPhone,
  selectCandidateStatuses,
} from "services/store/modules/candidate/selectors";
import {
  CandidateStatus,
  CandidateType,
  RefusalReasonType,
  RefusalType,
} from "graphql/types/types";
import { FormSelect } from "components/UI/Form/FormSelect";
import FormText from "components/UI/Form/FormText";
import { CallButton } from "components/Calls/CallButton";
import { selectVacancyData } from "services/store/modules/vacancy/selectors";
import { Button } from "components/UI/Button";
import { ROUTE } from "routes";
import { getDateFormat } from "common/utils/date";
import { CandidateModals } from "components/CandidateModals";
import { CandidateHistory } from "./components/CandidateHistory";
import { Tooltip } from "components/UI/Tooltip";
import { Warning } from "components/UI/icons";
import { getUrlParams } from "common/utils/routes";
import {
  selectRefusalReasons,
  selectRefusalReasonsByType,
} from "services/store/modules/directory";
import { useCandidateStatus } from "common/hooks/candidate/useCandidateStatus";
import { getSelectOptions } from "components/UI/Select/helpers";
import FormDatePicker from "components/UI/Form/FormDatePicker";
import { buildItemsById } from "services/store/helpers";
import { stringifyQuery } from "common/utils/query";
import { removeCandidate } from "services/store/modules/candidates";
import { setCandidate } from "services/store/modules/candidate";
import { getCandidateStatusType } from "components/CandidatesTable/helpers";
import { REFUSAL_REASONS_REQUIRING_DATE_KEYS } from "common/const/candidate";

import "./styles.scss";

type Props = {
  candidate: CandidateType;
};

export const CandidateActions: React.FC<Props> = ({ candidate }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    reason,
    date,
    denied,
    notThrough,
    type,
    setReason,
    setDenied,
    setNotThrough,
    setDate,
    setType,
    updateCandidateStatus,
  } = useCandidateStatus({ isShowToaster: true });

  const candidateStatuses = useAppSelector(selectCandidateStatuses);
  const refusalDirectory = useAppSelector(selectRefusalReasons);
  const refusalReasons = useAppSelector((state) =>
    selectRefusalReasonsByType(state, RefusalType.CandidateRefusal)
  );
  const deniedReasons = useAppSelector((state) =>
    selectRefusalReasonsByType(state, RefusalType.CandidateDenied)
  );
  const notThroughReasons = useAppSelector((state) =>
    selectRefusalReasonsByType(state, RefusalType.CandidateNotThrough)
  );

  const vacancy = useAppSelector(selectVacancyData);
  const candidateHistory = useAppSelector(selectCandidateHistory);
  const { contact } = useAppSelector(selectCandidateMainPhone) || {};

  const { fromVacancy } = getUrlParams();
  const numericVacancyId = Number(fromVacancy);
  const {
    id: candidateId,
    status,
    updater,
    updatedAt,
    vacancyCount,
  } = candidate;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [candidateStatus, setCandidateStatus] = useState(
    status as CandidateStatus
  );
  const [loading, setLoading] = useState(false);

  const [showRefusalDatePicker, setShowRefusalDatePicker] =
    useState<boolean>(false);

  const goToRecommended = () => {
    history.push({
      pathname: `${ROUTE.RECOMMENDED}/${candidateId}`,
      search: fromVacancy ? stringifyQuery({ fromVacancy }) : "",
    });
  };

  const updateStatus = useCallback(
    async (
      status: CandidateStatus,
      reason?: RefusalReasonType["id"] | null,
      date?: Moment | null
    ) => {
      setLoading(true);

      await updateCandidateStatus({
        id: candidateId,
        status,
        callTime: date,
        refusalId: reason ?? undefined,
        vacancyId: numericVacancyId ? numericVacancyId : null,
      }).then((result) => {
        dispatch(removeCandidate({ candidateId: candidate.id }));

        if (result?.changeCandidateStatus) {
          dispatch(setCandidate(result.changeCandidateStatus));
        }
        if (
          CandidateStatus.Candidate === status &&
          numericVacancyId === vacancy?.id
        ) {
          setIsModalOpen(true);
        }
      });

      setType(null);
      setLoading(false);
    },
    [
      candidate.id,
      candidateId,
      dispatch,
      numericVacancyId,
      setType,
      updateCandidateStatus,
      vacancy?.id,
    ]
  );

  const handleStatusChange = (status: CandidateStatus) => {
    setCandidateStatus(status);

    const type = getCandidateStatusType(status);

    if (type) {
      return setType(type);
    }

    updateStatus(status);
  };

  const handleRefusalChange = (
    value: number,
    reasonType: "reason" | "denied" | "notThrough"
  ) => {
    const setStateMap = {
      reason: setReason,
      denied: setDenied,
      notThrough: setNotThrough,
    };

    setStateMap[reasonType](value);

    if (reasonType === "reason") {
      const selectedReason = refusalReasons.find(
        (reason) => reason.id === value
      );

      const requiresDate = selectedReason
        ? REFUSAL_REASONS_REQUIRING_DATE_KEYS.includes(selectedReason.key)
        : false;

      setShowRefusalDatePicker(requiresDate);

      if (!requiresDate) {
        updateStatus(candidateStatus, value);
      }
    } else {
      updateStatus(candidateStatus, value);
    }
  };

  const handleDateChange = (date: Moment | null) => {
    setDate(date);
    updateStatus(
      candidateStatus,
      showRefusalDatePicker ? reason : undefined,
      date
    );
    setShowRefusalDatePicker(false);
  };

  const typeConfig = {
    reason: {
      options: getSelectOptions(refusalReasons),
      value: reason,
      onChange: (value: number) => handleRefusalChange(value, "reason"),
      label: "Причина отказа",
    },
    denied: {
      options: getSelectOptions(deniedReasons),
      value: denied,
      onChange: (value: number) => handleRefusalChange(value, "denied"),
      label: "Причина отказа кандидата",
    },
    notThrough: {
      options: getSelectOptions(notThroughReasons),
      value: notThrough,
      onChange: (value: number) => handleRefusalChange(value, "notThrough"),
      label: "Причина недозвона",
    },
  };

  const currentConfig = type ? typeConfig[type] : null;

  useEffect(() => {
    if (status) {
      setCandidateStatus(status);
    }
  }, [status]);

  return (
    <div className="candidates-actions">
      {fromVacancy && (
        <FormText
          className="candidates-actions-vacancy"
          label="Вакансия"
          text={vacancy?.name}
        />
      )}
      <div className="candidates-actions-btns">
        {candidate && Object.keys(candidate).length > 0 && (
          <CallButton
            candidate={candidate}
            contact={contact}
            vacancyId={numericVacancyId}
          />
        )}
      </div>

      <FormSelect
        label="Статус"
        value={candidateStatus}
        style={{ width: "100%" }}
        loading={loading}
        options={getSelectOptions(candidateStatuses)}
        onChange={handleStatusChange}
      />

      {currentConfig && (
        <FormSelect
          label={currentConfig.label}
          defaultValue="Укажите причину"
          value={currentConfig.value}
          style={{ width: "100%" }}
          loading={loading}
          options={currentConfig.options}
          onChange={currentConfig.onChange}
        />
      )}

      {(type === "date" || showRefusalDatePicker) && (
        <FormDatePicker
          label={type === "date" ? "Дата и время" : "Дата"}
          style={{ width: "100%" }}
          format={type === "date" ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY"}
          value={date}
          showTime={type === "date"}
          onChange={handleDateChange}
        />
      )}

      <FormText
        label="ФИО заполнившего карточку"
        className="candidates-actions-updater"
        text={
          updater ? (
            <>
              <span>{`${updater.firstName} ${updater.secondName}`}</span>
              <span>{getDateFormat(updatedAt, "DD.MM.YYYY")}</span>
            </>
          ) : (
            "Изменений по кандидату не было."
          )
        }
      />
      {!Boolean(vacancyCount) && (
        <Tooltip
          placement="bottomLeft"
          title="Подходящие для кандидата вакансии отсутствуют"
        >
          <div className="candidates-actions-tooltip">
            <Warning />
          </div>
        </Tooltip>
      )}
      <Button
        onClick={goToRecommended}
        disabled={!Boolean(vacancyCount)}
        className="candidates-actions-btn"
      >
        Подобрать вакансии
      </Button>
      {candidateHistory?.items.length ? (
        <CandidateHistory
          refusalReasonsByKey={buildItemsById(refusalDirectory, "key")}
          historyItems={candidateHistory.items}
        />
      ) : null}
      <CandidateModals
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        mode="candidate"
      />
    </div>
  );
};
