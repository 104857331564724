import { RootState } from "../../rootReducer";
import { createSelector } from "reselect";

const selectRepliesNew = (state: RootState) => state.repliesNew;

export const selectRepliesNewList = createSelector(
  selectRepliesNew,
  (state) => state.repliesNewList
);

export const selectRepliesNewListMeta = createSelector(
  selectRepliesNewList,
  (state) => state.meta
);

export const selectRepliesNewListData = createSelector(
  selectRepliesNewList,
  (state) => state.data
);
export const selectRepliesNewListLoading = createSelector(
  selectRepliesNewList,
  (state) => state.loading
);

export const selectReplyNew = createSelector(
  selectRepliesNew,
  (state) => state.replyNew.data
);
export const selectReplyNewLoading = createSelector(
  selectRepliesNew,
  (state) => state.replyNew.loading
);
