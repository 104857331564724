import React from "react";
import {
  Control,
  FieldErrors,
  UseFormSetError,
  UseFormClearErrors,
} from "react-hook-form";

import { Modal } from "components/UI/Modal";
import { Button } from "components/UI/Button";
import { Filters } from "components/UI/Filters";
import { ReportName } from "common/const/reports";
import { useAppSelector } from "services/store/store";
import { department } from "services/store/modules/directory";
import { IUser } from "services/store/modules/user/types";

import { CallCenterFilters } from "../CallCenterFilters";
import { PilotFilters } from "../PilotFilters";
import { FiltersState } from "../../types";
import { MangoFilters } from "../MangoFilters";

import "./styles.scss";

type Props = {
  open: boolean;
  isDirty: boolean;
  loading: boolean;
  control: Control<FiltersState>;
  errors: FieldErrors<FiltersState>;
  reportName: ReportName;
  managerList: IUser[];
  className?: string;
  onCancel: () => void;
  omSubmit: () => Promise<void>;
  onReset: () => void;
  setError: UseFormSetError<FiltersState>;
  clearErrors: UseFormClearErrors<FiltersState>;
};

export const FiltersModal: React.FC<Props> = ({
  open,
  isDirty,
  loading,
  control,
  errors,
  reportName,
  managerList,
  onCancel,
  omSubmit,
  onReset,
  setError,
  clearErrors,
}) => {
  const departmentList = useAppSelector(department);
  const isError = Boolean(Object.values(errors).length);

  const filtersByReportNameComponent: Record<ReportName, React.ReactElement> = {
    [ReportName.ReportCallCenter]: (
      <CallCenterFilters
        control={control}
        errors={errors}
        departmentList={departmentList}
        managerList={managerList}
        setError={setError}
        clearErrors={clearErrors}
      />
    ),
    [ReportName.ReportPilot]: (
      <PilotFilters
        control={control}
        errors={errors}
        departmentList={departmentList}
        managerList={managerList}
        setError={setError}
        clearErrors={clearErrors}
      />
    ),
    [ReportName.ReportMango]: (
      <MangoFilters
        control={control}
        errors={errors}
        managerList={managerList}
        setError={setError}
        clearErrors={clearErrors}
      />
    ),
  };

  const handleClick = async () => {
    await omSubmit();
  };

  return (
    <Modal
      className="filtersModal"
      open={open}
      size="small"
      onCancel={onCancel}
      footer={null}
    >
      <Filters className="filtersModal-filters" />

      {filtersByReportNameComponent[reportName]}

      <Button
        className="filtersModal-btn"
        loading={loading}
        disabled={isError}
        onClick={handleClick}
      >
        Применить
      </Button>
      <Button
        className="filtersModal-btn"
        variant="primary"
        disabled={!isDirty}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onReset();
        }}
      >
        Очистить все фильтры
      </Button>
    </Modal>
  );
};
