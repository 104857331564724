import {
  AddressInputType,
  CandidateStatus,
  CandidateType,
  CandidateUpdateType,
} from "graphql/types/types";

export const getUpdatedCandidatePayload = (
  candidate: CandidateType
): CandidateUpdateType => ({
  age: candidate.age,
  dismissReason: candidate.dismissReason,
  driverLicense: candidate.driverLicense,
  email: candidate.email,
  lastShiftRate: candidate.lastShiftRate,
  managerComment: candidate.managerComment,
  firstName: candidate.firstName,
  fullName: candidate.fullName ?? "",
  secondName: candidate.secondName,
  patronymic: candidate.patronymic,
  region: candidate.region,
  salaryExpectation: candidate.salaryExpectation,
  sex: candidate.sex,
  shiftMaxDate: candidate.shiftMaxDate,
  shiftMinDate: candidate.shiftMinDate,
  source: candidate.source,
  haveCar: candidate.haveCar,
  dateOfBirth: candidate.dateOfBirth,
  russianCitizenship: candidate.russianCitizenship,
  inBlacklist: candidate.inBlacklist,
  actualAddress: candidate.actualAddress as AddressInputType,
  cardDeliveryAddress: candidate.cardDeliveryAddress,
  metro: candidate.metro,
  positionId: candidate.positionId,
  positions: candidate.positions,
  badge: candidate.badge,
  calcDismissDate: candidate.calcDismissDate,
  citizenshipId: candidate.citizenshipId,
  city: candidate.city,
  companyId: candidate.companyId,
  departmentId: candidate.departmentId,
  dismissDate: candidate.dismissDate,
  experienceFull: candidate.experienceFull,
  experienceInCompany: candidate.experienceInCompany,
  hasChat: candidate.hasChat,
  isSpam: candidate.isSpam,
  managerId: candidate.managerId,
  ownerId: candidate.ownerId,
  receptionLastDate: candidate.receptionLastDate,
  status: candidate.status ?? undefined,
});

export const getCandidateStatusType = (status: CandidateStatus) =>
  (status === CandidateStatus.RefusalCandidate && "reason") ||
  (status === CandidateStatus.Callback && "date") ||
  (status === CandidateStatus.Denied && "denied") ||
  (status === CandidateStatus.NotThrough && "notThrough") ||
  null;
