import { useCallback, useState } from "react";
import { Moment } from "moment";

import { useAppDispatch } from "services/store/store";
import { changeCandidateStatus } from "services/store/modules/candidate/actions";
import {
  MutationChangeCandidateStatusArgs,
  RefusalReasonType,
} from "graphql/types/types";
import toaster from "components/UI/Notifications/Notification";

type Props = {
  isShowToaster?: boolean;
  titleSuccess?: string;
  titleError?: string;
};

const SUCCESS_MESSAGE = "Статус успешно изменен";

type CandidateActionType =
  | "date"
  | "reason"
  | "denied"
  | "notThrough"
  | "reasonDate"
  | null;

export const useCandidateStatus = ({
  isShowToaster,
  titleSuccess,
  titleError,
}: Props) => {
  const dispatch = useAppDispatch();

  const [reason, setReason] = useState<RefusalReasonType["id"] | null>(null);
  const [denied, setDenied] = useState<RefusalReasonType["id"] | null>(null);
  const [notThrough, setNotThrough] = useState<RefusalReasonType["id"] | null>(
    null
  );
  const [date, setDate] = useState<Moment | null>(null);
  const [type, setType] = useState<CandidateActionType>(null);

  const updateCandidateStatus = useCallback(
    async (payload: MutationChangeCandidateStatusArgs) => {
      return dispatch(changeCandidateStatus(payload))
        .unwrap()
        .then((response) => {
          if (isShowToaster) {
            toaster.success({ title: titleSuccess || SUCCESS_MESSAGE });
          }
          return response;
        })
        .catch((error) => {
          if (isShowToaster) {
            toaster.error({ title: titleError || `Ошибка: ${error}` });
          }
          return null;
        });
    },
    [dispatch, isShowToaster, titleError, titleSuccess]
  );

  return {
    reason,
    date,
    denied,
    notThrough,
    type,
    setReason,
    setDate,
    setDenied,
    setNotThrough,
    setType,
    updateCandidateStatus,
  };
};
