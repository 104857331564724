import React, { useRef } from "react";
import ReactQuill, { ReactQuillProps } from "react-quill";
import cn from "classnames/dedupe";

import { VALIDATION_MESSAGE } from "common/const/messages";

import { FormLabel } from "../Form/FormLabel";
import { COUNT_TEXT, toolbar } from "./constants";

import "react-quill/dist/quill.snow.css";
import "./styles.scss";

export type Props = ReactQuillProps & {
  containerClassName?: string;
  withToolbar?: boolean;
  label?: string;
  error?: string;
  required?: boolean;
  count?: number;
  disabled?: boolean;
};

export const Editor: React.FC<Props> = ({
  className,
  containerClassName,
  required,
  value,
  count,
  label,
  error,
  disabled,
  withToolbar = true,
  onChange,
}) => {
  const ref = useRef<ReactQuill | null>(null);
  const { current: editorRef } = ref;

  const editorLength = editorRef?.getEditor()?.getLength();
  const valueLength = value && typeof value === "string" ? value.length : 0;

  return (
    <div className={cn("ui-editor", containerClassName)}>
      {label && (
        <FormLabel
          className="ui-editor-label"
          label={label}
          required={required}
        />
      )}

      <ReactQuill
        ref={ref}
        className={cn("ui-editor-textarea", className, {
          "ui-editor-textarea--disabled": disabled,
        })}
        theme="snow"
        value={value}
        onChange={(value, ...args) => {
          (editorLength ?? value?.trim().length) > 1
            ? onChange?.(value, ...args)
            : onChange?.("", ...args);
        }}
        modules={{
          ...(withToolbar ? { toolbar } : {}),
        }}
      />
      {count && (
        <div className="ui-editor-character">
          {valueLength <= count || 0 ? (
            <div className="ui-editor-character-text">{COUNT_TEXT}</div>
          ) : (
            <div className="ui-editor-character-error">
              {error ?? VALIDATION_MESSAGE.MAX_LIMIT}
            </div>
          )}

          <div className="ui-editor-character-count">
            {(editorLength || 0) > 1
              ? `${value?.length || 0}/${count}`
              : `0/${count}`}
          </div>
        </div>
      )}
    </div>
  );
};
