import { combineReducers } from "@reduxjs/toolkit";
import { authModuleReducer, userListReducer } from "./modules/auth";
import { userReducer } from "./modules/user/index";
import directoryModule from "./modules/directory";
import companyModule from "./modules/company";
import repliesModule from "./modules/replies";
import claimsModule from "./modules/claims";
import vacanciesModule from "./modules/vacancies";
import socketsModule from "./modules/sockets";
import callsModule from "./modules/calls";
import { vacanciesListReducer } from "./modules/vacanciesList";
import { candidatesReducer } from "./modules/candidates";
import { vacancyReducer } from "./modules/vacancy";
import { repliesListReducer } from "./modules/repliesList";
import { appReducer } from "./modules/app";
import { reportsReducer } from "./modules/reports";
import { settingsReducer } from "./modules/settings";
import { claimsListReducer } from "./modules/claimsList";
import { callsMangoReducer } from "./modules/callsMango";
import { plansReducer } from "./modules/plans";
import { candidateReducer } from "./modules/candidate";
import { repliesNewReducer } from "./modules/repliesNew";

const rootReducer = combineReducers({
  app: appReducer,
  auth: authModuleReducer,
  calls: callsModule,
  candidate: candidateReducer,
  candidates: candidatesReducer,
  claims: claimsModule,
  company: companyModule,
  directory: directoryModule,
  replies: repliesModule,
  repliesList: repliesListReducer,
  repliesNew: repliesNewReducer,
  reports: reportsReducer,
  plans: plansReducer,
  settings: settingsReducer,
  sockets: socketsModule,
  user: userReducer,
  userList: userListReducer,
  vacancies: vacanciesModule,
  vacanciesList: vacanciesListReducer,
  vacancy: vacancyReducer,
  claimsList: claimsListReducer,
  callsMango: callsMangoReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
