import React from "react";
import { Form } from "antd";
import type { RangePickerProps } from "antd/lib/date-picker";
import cn from "classnames/dedupe";

import { RangePicker } from "../../RangePicker";
import { FormLabel } from "../FormLabel";
import { FormError } from "../FormError";

import "./styles.scss";

type Props = RangePickerProps & {
  label: string;
  required?: boolean;
  error?: string;
};

export const FormRangePicker: React.FC<Props> = ({
  className,
  label,
  error,
  required,
  ...props
}) => {
  return (
    <Form.Item
      label={<FormLabel label={label} required={required} />}
      className={cn("ui-form-range-picker", className)}
    >
      <RangePicker {...props} />
      {error && <FormError error={error} />}
    </Form.Item>
  );
};
