import {
  PayloadAction,
  createSlice,
  isAnyOf,
  combineReducers,
} from "@reduxjs/toolkit";

import { CandidateType, Dictionaries } from "graphql/types/types";
import {
  fetchCandidateById,
  fetchCandidateDictionaries,
  getCandidateHistory,
  setupCallCandidate,
} from "./actions";
import { CandidateHistoryResponse } from "api/history";
import { getInitialState } from "../../helpers";

const initialState = getInitialState<CandidateType>();

const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    clearCandidateData: (state) => {
      state = initialState;
    },
    setCandidate: (state, { payload }: PayloadAction<CandidateType>) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidateById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload?.candidate || null;
      })
      .addCase(setupCallCandidate.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addMatcher(
        isAnyOf(fetchCandidateById.pending, setupCallCandidate.pending),
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        isAnyOf(fetchCandidateById.rejected, setupCallCandidate.rejected),
        (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        }
      );
  },
});

const candidateHistorySlice = createSlice({
  name: "candidateHistory",
  initialState: getInitialState<CandidateHistoryResponse>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCandidateHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCandidateHistory.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(getCandidateHistory.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload as string;
      });
  },
});

const candidateDictionariesSlice = createSlice({
  name: "candidateDictionaries",
  initialState: getInitialState<Dictionaries>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidateDictionaries.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCandidateDictionaries.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.dictionaries;
      })
      .addCase(fetchCandidateDictionaries.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload as string;
      });
  },
});

export const { clearCandidateData, setCandidate } = candidateSlice.actions;

export const candidateReducer = combineReducers({
  value: candidateSlice.reducer,
  history: candidateHistorySlice.reducer,
  dictionaries: candidateDictionariesSlice.reducer,
});
