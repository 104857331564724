import { gql } from "@apollo/client";

export const REPLY_ITEM = gql`
  query reply($id: ID!) {
    reply(id: $id) {
      ... on ReplyType {
        id
        actionPayClick
        address {
          area
          areaType
          block
          blockType
          city
          cityType
          entrance
          house
          houseType
          lat
          lng
          region
          regionType
          settlement
          settlementType
          street
          streetType
          value
        }
        age
        candidate {
          acceptedAt
          actualAddress {
            area
            areaType
            block
            blockType
            city
            cityType
            entrance
            house
            houseType
            lat
            lng
            region
            regionType
            settlement
            settlementType
            street
            streetType
            value
          }
          age
          badge
          calcDismissDate
          callCompleted
          cardDeliveryAddress {
            area
            areaType
            block
            blockType
            city
            cityType
            entrance
            house
            houseType
            lat
            lng
            region
            regionType
            settlement
            settlementType
            street
            streetType
            value
          }
          citizenshipId
          city
          comments {
            comment
            createdAt
            manager {
              city
              companyId
              departmentId
              email
              firstName
              id
              phone
              role
              secondName
            }
          }
          companyId
          contacts {
            contact
            id
            isMain
          }
          createdAt
          dateOfBirth
          deletedAt
          departmentId
          dismissDate
          dismissReason
          driverLicense
          email
          experienceFull
          experienceInCompany
          firstName
          fullName
          hasChat
          hasHistory
          haveCar
          id
          inBlacklist
          isSpam
          lastShiftRate
          location {
            lat
            lng
          }
          managerComment
          managerId
          metro {
            distance
            line
            name
          }
          ownerId
          patronymic
          positionId
          positions {
            key
          }
          positionsNew {
            createdAt
            id
            position {
              categoryId
              id
              key
              name
              restriction
            }
          }
          receptionLastDate
          region
          resumeUrl
          russianCitizenship
          salaryExpectation
          secondName
          sex
          shiftMaxDate
          shiftMinDate
          source
          status
          timezone
          transitDuration
          updatedAt
          updatedBy
          updater {
            city
            companyId
            departmentId
            email
            firstName
            id
            phone
            role
            secondName
          }
          vacancyCount
          walkingDuration
        }
        candidateId
        chatLink
        citizenshipId
        cityAdsClick
        claimId
        companyId
        contacts {
          email
          fullName
          phone
        }
        createdAt
        deletedAt
        departmentId
        employmentId
        expiredAt
        expiredAtAfterTransferFromCc
        expiredCallTime
        expiredTime
        expiredTimeAfterTransferFromCc
        firstName
        hasChat
        inWork
        isCreatedAtBusinessHours
        isExpiredAtBusinessHours
        isMain
        isTransferFromCcAtBusinessHours
        manager {
          city
          companyId
          departmentId
          email
          firstName
          id
          phone
          role
          secondName
        }
        managerComment
        managerId
        notCallCount
        owner {
          city
          companyId
          departmentId
          email
          firstName
          id
          phone
          role
          secondName
        }
        ownerId
        patronymic
        positionId
        refusalId
        refusalText
        russianCitizenship
        s2Id
        secondName
        source
        status
        updatedAt
        updatedBy
        userComment
        userContent
        utm {
          campaign
          content
          medium
          source
          term
        }
        vacancy {
          additionalAvito {
            bonuses
            employment
            experience
            profession
            registrationMethod
            schedule
            title
            workFormat
          }
          additionalHh {
            acceptHandicapped
            acceptKids
            acceptTemporary
            area
            driverLicense
            experience
            languages
            name
            professionalRoles
          }
          address {
            area
            areaType
            block
            blockType
            city
            cityType
            entrance
            house
            houseType
            lat
            lng
            region
            regionType
            settlement
            settlementType
            street
            streetType
            value
          }
          age {
            from
            to
          }
          callCenterInfo {
            addresses
            description
            driverLicense
            experience
            farePayment
            goodsCategory
            mobilePayment
            personalCar
            presenceOfPda
            project
            vaccination
          }
          candidateCount
          citizenship {
            id
            key
            name
          }
          city
          claims
          closedAt
          contacts {
            email
            fullName
            phone
          }
          counterparty
          createdAt
          deletedAt
          description
          employer {
            id
            key
            logo
            name
          }
          employment {
            id
            key
            name
          }
          extraAvito {
            publicationFinishTime
            remoteId
            remoteUrl
          }
          extraHh {
            publicationFinishTime
            remoteId
            remoteUrl
          }
          id
          inBlocked
          inWork
          kladrId
          location {
            lat
            lng
          }
          manager {
            city
            companyId
            departmentId
            email
            firstName
            id
            phone
            role
            secondName
          }
          medicalBook
          metro {
            distance
            line
            name
          }
          name
          nationalities {
            nationality {
              id
              key
              name
            }
          }
          peopleCount
          peopleCountClose
          peopleCountOpen
          phonePresence
          platformTags
          position {
            categoryId
            id
            countPeople
            key
            name
            restriction
          }
          positionId
          priority
          publishedAt
          publishedOnAvito
          publishedOnHh
          publishedOnRabotut
          reason
          refusal {
            id
            key
            name
            type
          }
          refusalText
          region
          relevance
          replyCount
          replyCountNew
          responsiblePerson
          route
          salary {
            currency
            from
            gross
            to
          }
          schedule
          shiftEnd
          shiftStart
          shortDescription
          showContacts
          status
          subdivision
          tag {
            color
            colorBg
            key
            name
          }
          terms
          tradePoint
          updatedAt
        }
        vacancyId
      }
      ... on Error {
        statusCode
        errors
        message
      }
    }
  }
`;
