import { gql } from "@apollo/client";

export const USER_NEW_TRANSFER_LIST = gql`
  query userNewTransferList {
    userNewTransferList {
      ... on UserClientListSuccess {
        data {
          city
          companyId
          createdAt
          deletedAt
          departmentId
          email
          firstName
          id
          mangoId
          phone
          role
          secondName
          tz
          updatedAt
        }
        meta {
          limit
          offset
          total
        }
      }
      ... on Error {
        message
        statusCode
        errors
      }
    }
  }
`;
