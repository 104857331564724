import React from "react";
import { Button } from "components/UI/Button";
import { FormSelect } from "components/UI/Form/FormSelect";
import { Collapse, PanelProps } from "components/UI/Collapse";
import { IStatus } from "services/store/modules/directory/types";
import { ReplyNewStatuses, ReplyStatus } from "graphql/types/types";
import { ClaimStatus } from "services/store/modules/claims/types";
import { getSelectOptions } from "components/UI/Select/helpers";

import "./styles.scss";

interface Props {
  statuses: IStatus[];
  currentStatus?: ReplyStatus | ClaimStatus | ReplyNewStatuses;
  isDisabled?: boolean;
  onStatusChange: (status) => void;
  onVisibilityChange: () => void;
  panels?: PanelProps[];
}

export const ActionPanel: React.FC<Props> = ({
  statuses,
  currentStatus,
  isDisabled,
  onStatusChange,
  onVisibilityChange,
  panels,
}) => {
  return (
    <div className="action-panel-container">
      <Button
        className="action-panel-container-btn"
        disabled={isDisabled}
        onClick={onVisibilityChange}
      >
        Взять в работу
      </Button>
      <FormSelect
        label="Статус"
        value={currentStatus}
        style={{ width: "100%" }}
        options={getSelectOptions(statuses)}
        onChange={(value) => onStatusChange(value)}
      />
      {panels && <Collapse panels={panels} expandIconPosition="end" />}
    </div>
  );
};
