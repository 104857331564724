import React from "react";
import {
  Control,
  Controller,
  UseFormSetError,
  UseFormClearErrors,
  FieldErrors,
} from "react-hook-form";

import { FormInput } from "components/UI/Form/FormInput";
import { PilotsFiltersState } from "../../types";
import { FormSelect } from "components/UI/Form/FormSelect";
import { filterOption } from "components/UI/Select/helpers";
import { DepartmentType } from "graphql/types/types";
import { IUser } from "services/store/modules/user/types";
import { DateController } from "../DateController";

type Props = {
  control: Control<PilotsFiltersState>;
  departmentList: DepartmentType[];
  managerList: IUser[];
  errors: FieldErrors<PilotsFiltersState>;
  setError: UseFormSetError<PilotsFiltersState>;
  clearErrors: UseFormClearErrors<PilotsFiltersState>;
};

export const PilotFilters: React.FC<Props> = ({
  control,
  departmentList,
  managerList,
  errors,
  setError,
  clearErrors,
}) => {
  return (
    <>
      <DateController
        control={control}
        errors={errors}
        setError={setError}
        clearErrors={clearErrors}
      />

      <Controller
        name="managerId"
        control={control}
        render={({ field }) => (
          <FormSelect
            label="ФИО менеджера КЦ"
            placeholder="Выберите менеджера"
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
            options={managerList?.map(({ fullName, id }) => ({
              label: fullName,
              value: id,
            }))}
            {...field}
          />
        )}
      />

      <Controller
        name="departamentId"
        control={control}
        rules={{ required: false }}
        render={({ field }) => (
          <FormSelect
            label={"Департамент"}
            placeholder="Выберите департамент"
            options={departmentList.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
            {...field}
          />
        )}
      />

      <Controller
        name="projectName"
        control={control}
        render={({ field }) => (
          <FormInput
            label={"Проект"}
            placeholder="Выберите проект"
            {...field}
          />
        )}
      />

      <Controller
        name="vacancyId"
        control={control}
        render={({ field }) => (
          <FormInput
            label={"ID вакансии"}
            placeholder="Укажите ID вакансии"
            {...field}
          />
        )}
      />

      <Controller
        name="vacancyName"
        control={control}
        render={({ field }) => (
          <FormInput
            label={"Название вакансии"}
            placeholder="Укажите название вакансии"
            {...field}
          />
        )}
      />

      <Controller
        name="candidateName"
        control={control}
        render={({ field }) => (
          <FormInput
            label={"ФИО кандидата"}
            placeholder="Укажите ФИО кандидата"
            {...field}
          />
        )}
      />
    </>
  );
};
