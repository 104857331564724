import { gql } from "@apollo/client";

export const REPLY_NEW_LIST = gql`
  query replyNewList($pagination: PaginationType) {
    replyNewList(pagination: $pagination) {
      ... on ReplyNewListSuccess {
        data {
          candidate {
            acceptedAt
            actualAddress {
              area
              areaType
              block
              blockType
              city
              cityType
              entrance
              house
              houseType
              lat
              lng
              region
              regionType
              settlement
              settlementType
              street
              streetType
              value
            }
            age
            badge
            calcDismissDate
            callCompleted
            cardDeliveryAddress {
              area
              areaType
              block
              blockType
              city
              cityType
              entrance
              house
              houseType
              lat
              lng
              region
              regionType
              settlement
              settlementType
              street
              streetType
              value
            }
            citizenshipId
            city
            comments {
              comment
              createdAt
              manager {
                city
                companyId
                departmentId
                email
                firstName
                id
                phone
                role
                secondName
              }
            }
            companyId
            contacts {
              contact
              id
              isMain
            }
            createdAt
            dateOfBirth
            deletedAt
            departmentId
            didntWork
            dismissDate
            dismissReason
            driverLicense
            email
            experienceFull
            experienceInCompany
            firstName
            fullName
            hasChat
            hasHistory
            haveCar
            id
            inBlacklist
            isSpam
            lastShiftRate
            location {
              lat
              lng
            }
            managerComment
            managerId
            metro {
              distance
              line
              name
            }
            ownerId
            patronymic
            positionId
            positions {
              key
            }
            positionsNew {
              createdAt
              id
              position {
                categoryId
                id
                key
                name
                restriction
              }
            }
            receptionLastDate
            region
            resumeUrl
            russianCitizenship
            salaryExpectation
            secondName
            sex
            shiftMaxDate
            shiftMinDate
            source
            status
            timezone
            transitDuration
            updatedAt
            updatedBy
            updater {
              city
              companyId
              departmentId
              email
              firstName
              id
              phone
              role
              secondName
            }
            vacancyCount
            walkingDuration
          }
          city
          createdAt
          firstName
          id
          isExpired
          isThirtyMinutes
          inProgressBy
          lastModifiedBy
          lastName
          manager {
            city
            companyId
            createdAt
            deletedAt
            departmentId
            email
            firstName
            id
            mangoId
            phone
            role
            secondName
            tz
            updatedAt
          }
          patronymic
          phone
          resume
          source
          status
          updatedAt
          utm {
            campaign
            content
            medium
            source
            term
          }
          vacancy {
            additionalAvito {
              bonuses
              employment
              experience
              managerId
              profession
              registrationMethod
              schedule
              title
              whoPublished
              workFormat
            }
            additionalHh {
              acceptHandicapped
              acceptKids
              acceptTemporary
              area
              driverLicense
              experience
              languages
              name
              professionalRoles
            }
            address {
              area
              areaType
              block
              blockType
              city
              cityType
              entrance
              house
              houseType
              lat
              lng
              region
              regionType
              settlement
              settlementType
              street
              streetType
              value
            }
            age {
              from
              to
            }
            callCenterInfo {
              additionalMotivation
              addresses
              compensationMedicalBook
              description
              driverLicense
              experience
              farePayment
              goodsCategory
              mobilePayment
              payoutsRfp
              personalCar
              presenceOfPda
              project
              registrationOfForeignCitizens
              typesOfJobRegistration
              vaccination
            }
            candidateCount
            citizenship {
              id
              key
              name
            }
            city
            claims
            closedAt
            contacts {
              email
              fullName
              phone
            }
            counterparty
            createdAt
            deletedAt
            departmentId
            description
            employer {
              id
              key
              logo
              name
            }
            employment {
              id
              key
              name
            }
            extraAvito {
              message
              publicationFinishTime
              remoteId
              remoteUrl
              state
            }
            extraHh {
              message
              publicationFinishTime
              remoteId
              remoteUrl
              state
            }
            id
            inBlocked
            inWork
            kladrId
            location {
              lat
              lng
            }
            manager {
              city
              companyId
              departmentId
              email
              firstName
              id
              phone
              role
              secondName
            }
            managersIds
            medicalBook
            metro {
              distance
              line
              name
            }
            name
            nationalities {
              nationality {
                id
                key
                name
              }
            }
            peopleCount
            peopleCountClose
            peopleCountOpen
            phonePresence
            platformTags
            position {
              categoryId
              countPeople
              id
              key
              name
              restriction
            }
            positionId
            priority
            publishedAt
            publishedOnAvito
            publishedOnHh
            publishedOnRabotut
            rate
            reason
            refusal {
              id
              isRepeated
              key
              name
              type
            }
            refusalText
            region
            relevance
            replyCount
            replyCountNew
            responsiblePerson
            route
            salary {
              currency
              from
              gross
              to
            }
            schedule
            shiftEnd
            shiftStart
            shortDescription
            showContacts
            status
            subdivision
            tag {
              color
              colorBg
              key
              name
            }
            terms
            tradePoint
            updatedAt
          }
        }
        meta {
          limit
          offset
          total
        }
      }
      ... on Error {
        message
        statusCode
        errors
      }
    }
  }
`;
