import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames/dedupe";

import { useAppSelector } from "services/store/store";
import { useAppDispatch } from "services/store/store";
import { Title } from "components/UI/Title";
import { Table, Props as TableProps } from "components/UI/Table";
import { Spinner } from "components/UI/Spinner";
import { VacancyType } from "graphql/types/types";
import {
  selectPositionCategories,
  selectCategoryById,
  selectVacanciesListByCategory,
  selectActiveCategory,
  selectActiveVacancyById,
  selectDisabledVacancyList,
  selectDailyPlanListLoading,
  selectDailyPlanListVacancy,
} from "services/store/modules/vacanciesList/selectors";
import { fetchVacancyListByCategory } from "services/store/modules/vacanciesList/actions";
import { PlanTable } from "../../PlanTable";
import { department, positions } from "services/store/modules/directory";
import { Pagination, PaginationPayload } from "components/UI/Pagination";
import {
  setActiveCategory,
  setActiveVacancyId,
} from "services/store/modules/vacanciesList";
import { ROUTE } from "routes";
import { Question } from "components/UI/icons";
import { Tooltip } from "components/UI/Tooltip";
import { VacancyStatus } from "components/VacancyStatus";
import { CANDIDATE_COUNT_TOOLTIP } from "./constants";
import { authInfo } from "services/store/modules/auth";
import { VacancyModals } from "./components/VacancyModals";
import { getTableRowDisabled } from "common/helpers/table";
import { getTrueName } from "common/helpers/string";
import { PAGINATION } from "common/const/pagination";

import "./styles.scss";

type Props = {
  className?: string;
};

export const Vacancies: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const loading = useAppSelector(selectDailyPlanListLoading);
  const dailyPlanList = useAppSelector(selectDailyPlanListVacancy);
  const { activeCategory } = useAppSelector(selectActiveCategory);
  const vacancyPositions = useAppSelector(positions);
  const departmentList = useAppSelector(department);
  const currentCategoryById = useAppSelector(selectCategoryById);
  const positionCategories = useAppSelector(selectPositionCategories);
  const activeVacancy = useAppSelector(selectActiveVacancyById);
  const { data: vacanciesList, loading: vacanciesListLoading } = useAppSelector(
    selectVacanciesListByCategory
  );
  const { data: vacancies, meta } = vacanciesList;
  const disabledVacancyList = useAppSelector(selectDisabledVacancyList);
  const userData = useAppSelector(authInfo);

  const columns: TableProps<VacancyType>["columns"] = [
    {
      title: "Категория",
      dataIndex: "position",
      key: "position",
      render: (position) => (
        <div className="nowrap">
          {positionCategories.find((item) => item.id === position.categoryId)
            ?.name ?? ""}
        </div>
      ),
    },
    {
      title: "Вакансия",
      dataIndex: "positionId",
      key: "positionId",
      width: 200,
      render: (positionId, vacancy) => (
        <div
          className="nowrap vacancies-container-category-name"
          title={
            vacancyPositions.find((el) => positionId === el.id)?.name ?? ""
          }
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setActiveVacancyId(vacancy.id));
          }}
        >
          {vacancyPositions.find((el) => positionId === el.id)?.name ??
            vacancy.position?.name}
        </div>
      ),
    },
    {
      title: "Торговая точка",
      dataIndex: "callCenterInfo",
      key: "callCenterInfo",
      render: (callCenterInfo) => {
        const project = callCenterInfo?.project ?? "";
        return (
          <div className="nowrap" title={project}>
            {project}
          </div>
        );
      },
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      width: 240,
      render: (address) => (
        <div className="nowrap" title={address.value}>
          {address.value}
        </div>
      ),
    },
    {
      title: "Зарплата",
      dataIndex: "salary",
      key: "salary",
      render: (salary) => <span>{salary.to ? `${salary.to} ₽` : ""}</span>,
    },
    {
      title: "Корп. транспорт",
      dataIndex: "medicalBook",
      key: "medicalBook",
      render: (medicalBook) => <span>{getTrueName(medicalBook)}</span>,
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status) => <VacancyStatus status={status} className="nowrap" />,
    },
    {
      title: (
        <div
          className="vacancies-container-candidateCount"
          title="Кол-во кандидатов"
        >
          <div className="nowrap">Кол-во кандидатов</div>
          <Tooltip placement="bottomLeft" title={CANDIDATE_COUNT_TOOLTIP}>
            <div>
              <Question color="#a6acb9" />
            </div>
          </Tooltip>
        </div>
      ),
      dataIndex: "candidateCount",
      key: "candidateCount",
    },
    {
      title: "Департамент",
      dataIndex: "departmentId",
      key: "departmentId",
      render: (departmentId) => (
        <span className="vacancies-container-status">
          {departmentList.find((dep) => dep.id === Number(departmentId))
            ?.name ?? ""}
        </span>
      ),
    },
    {
      title: "Общая потребность",
      dataIndex: "peopleCountOpen",
      key: "peopleCountOpen",
      render: (peopleCountOpen) => {
        return (
          <div className="nowrap" title={peopleCountOpen}>
            {peopleCountOpen}
          </div>
        );
      },
    },
    {
      title: "Закрыто",
      dataIndex: "peopleCountClose",
      key: "peopleCountClose",
      render: (peopleCountClose) => {
        return (
          <div className="nowrap" title={peopleCountClose}>
            {peopleCountClose}
          </div>
        );
      },
    },
  ];

  const getVacancies = useCallback(
    (categoryId, pagination) => {
      dispatch(
        fetchVacancyListByCategory({
          categoryId,
          pagination,
        })
      );
    },
    [dispatch]
  );

  const handleClick = useCallback(
    (
      categoryId: number,
      pagination: PaginationPayload = PAGINATION.VACANCIES
    ) => {
      dispatch(setActiveCategory(categoryId));
      getVacancies(categoryId, pagination);
    },
    [dispatch, getVacancies]
  );

  useEffect(() => {
    if (activeCategory && meta) {
      getVacancies(activeCategory, {
        offset: meta?.offset,
        limit: meta?.limit,
      });
    }
  }, [dispatch, getVacancies]);

  return (
    <div className="vacancies-container">
      {loading ? (
        <Spinner className="vacancies-container-spinner" loading={loading} />
      ) : (
        <>
          <Title type="h3">Персональный план и факт на день</Title>

          <PlanTable
            dailyPlanList={dailyPlanList}
            positionCategories={positionCategories}
            activeCategory={activeCategory}
            onClick={handleClick}
          />

          {currentCategoryById && (
            <div className="vacancies-container-list">
              <Title type="h3">{`Вакансии «${
                currentCategoryById?.name ?? ""
              }»`}</Title>

              <>
                {vacanciesListLoading ? (
                  <Spinner
                    className="vacancies-container-list-spinner"
                    loading={vacanciesListLoading}
                  />
                ) : (
                  <Table
                    tableLayout="fixed"
                    dataSource={vacancies}
                    columns={columns}
                    onRow={(vacancy) => ({
                      onClick: () => {
                        history.push(`${ROUTE.VACANCY}/${vacancy.id}`);
                      },
                      className: cn({
                        "vacancies-container-row--disabled":
                          getTableRowDisabled({
                            id: vacancy.id,
                            disabledList: disabledVacancyList,
                            ownerId: userData?.id,
                          }) || Boolean(vacancy.inBlocked),
                      }),
                    })}
                  />
                )}
                <Pagination
                  className="candidates-container-pagination"
                  total={meta?.total}
                  limit={meta?.limit}
                  offset={meta?.offset}
                  onChange={(pagination) =>
                    handleClick(Number(activeCategory), pagination)
                  }
                />
              </>
            </div>
          )}

          {activeVacancy && userData && (
            <VacancyModals
              vacancy={activeVacancy}
              disabledList={disabledVacancyList}
              user={userData}
              open={Boolean(activeVacancy)}
              onCancel={() => dispatch(setActiveVacancyId(null))}
            />
          )}
        </>
      )}
    </div>
  );
};
