import React, { useEffect } from "react";

import { ContentLayout } from "layouts/ContentLayout";
import { useAppDispatch } from "services/store/store";
import { fetchVacanciesDictionaries } from "services/store/modules/vacanciesList/actions";
import { RepliesNew } from "components/redezign/RepliesNew";
import { getUserClientList } from "services/store/modules/user/action";

export const RepliesNewPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    Promise.all([
      dispatch(fetchVacanciesDictionaries()),
      dispatch(getUserClientList()),
    ]);
  }, [dispatch]);

  return <ContentLayout centralColumn={{ content: <RepliesNew /> }} />;
};
