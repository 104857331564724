import { combineReducers, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserByID, IUser, UserState } from "./types";
import { IMeta } from "../../types";
import { UserClientType, UserType } from "../../../../graphql/types/types";
import { createMangoUser, getUserClientList } from "./action";
import { getInitialState } from "../../helpers";

export type UserClientTypeState = UserClientType[];

export const initialState: UserState = {
  search: "",
  userList: [],
  userByID: {},
  userListMeta: {
    total: 0,
    limit: 10,
    offset: 0,
  },
  getUserByID: undefined,
  historyUserList: {},
};

export const userModule = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSearch(state: UserState, { payload }: PayloadAction<string>) {
      state.search = payload;
    },
    setUserList(state: UserState, { payload }: PayloadAction<IUser[]>) {
      state.userList = payload;
    },
    setUserById(state: UserState, { payload }: PayloadAction<IUserByID>) {
      state.userByID = payload;
    },
    setUserListMeta(state: UserState, { payload }: PayloadAction<IMeta>) {
      state.userListMeta = payload;
    },
    setGetUserByID(state: UserState, { payload }: PayloadAction<IUser>) {
      state.getUserByID = payload;
    },
    setHistoryUser(state: UserState, { payload }: PayloadAction<UserType>) {
      const value = state.historyUserList;
      if (payload?.id) {
        value[payload.id] = payload;
        state.historyUserList = value;
      } else {
        console.error("user is not define");
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createMangoUser.fulfilled, (state, action) => {
      const updatedUser = action.payload?.createMangoUser;

      if (updatedUser) {
        state.userList = state.userList.map((user) =>
          user.id === updatedUser.id ? { ...user, ...updatedUser } : user
        );
      }
    });
  },
});

const userClientListSlice = createSlice({
  name: "userClientList",
  initialState: getInitialState<UserClientTypeState>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserClientList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserClientList.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload.userNewTransferList.data;
        state.meta = payload.userNewTransferList.meta;
      })
      .addCase(getUserClientList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});

// * mutations
export const { setUserList, setUserListMeta, setGetUserByID, setHistoryUser } =
  userModule.actions;

export const userReducer = combineReducers({
  item: userModule.reducer,
  userClientList: userClientListSlice.reducer,
});
