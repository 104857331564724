import React from "react";
import {
  Control,
  Controller,
  UseFormSetError,
  UseFormClearErrors,
  FieldErrors,
} from "react-hook-form";

import { FormSelect } from "components/UI/Form/FormSelect";
import { filterOption } from "components/UI/Select/helpers";
import { IUser } from "services/store/modules/user/types";
import { MangoFiltersState } from "../../types";
import { DateController } from "../DateController";

type Props = {
  managerList: IUser[];
  control: Control<MangoFiltersState>;
  errors: FieldErrors<MangoFiltersState>;
  setError: UseFormSetError<MangoFiltersState>;
  clearErrors: UseFormClearErrors<MangoFiltersState>;
};

export const MangoFilters: React.FC<Props> = ({
  control,
  errors,
  managerList,
  setError,
  clearErrors,
}) => {
  return (
    <>
      <DateController
        control={control}
        errors={errors}
        setError={setError}
        clearErrors={clearErrors}
      />

      <Controller
        name="managerId"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <FormSelect
            label="Кому"
            required={true}
            showSearch
            optionFilterProp="children"
            error={errors.managerId?.message || error?.message}
            filterOption={filterOption}
            options={managerList.map(({ fullName, id }) => ({
              label: fullName,
              value: id,
            }))}
            {...field}
          />
        )}
      />
    </>
  );
};
