import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  BoolSuccess,
  MutationChangeStatusNewReplyArgs,
  MutationHireNewReplyArgs,
  MutationTransferNewReplyListArgs,
  QueryReplyNewArgs,
  QueryReplyNewListArgs,
} from "graphql/types/types";
import { AsyncThunkConfig } from "services/store/types";
import { client as apollo } from "graphql/apollo";
import {
  ChangeStatusNewReplyResponse,
  HireNewReplyResponse,
  RepliesNewListResponse,
  ReplyNewResponse,
} from "./types";
import { REPLY_NEW_LIST } from "graphql/requests/query/replyNewList";
import { REPLY_NEW_ITEM } from "graphql/requests/query/replyNew";
import { HIRE_NEW_REPLY } from "graphql/requests/mutation/hireNewReply";
import { CHANGE_STATUS_NEW_REPLY } from "graphql/requests/mutation/changeStatusNewReply";
import { TRANSFER_NEW_REPLY_LIST } from "graphql/requests/mutation/transferNewReplyList";

export const getReplyNewList = createAsyncThunk<
  RepliesNewListResponse | null,
  QueryReplyNewListArgs,
  AsyncThunkConfig
>("repliesNew/replyNewList", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.query<RepliesNewListResponse>({
      query: REPLY_NEW_LIST,
      variables: payload,
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const getReplyNew = createAsyncThunk<
  ReplyNewResponse,
  QueryReplyNewArgs,
  AsyncThunkConfig
>("repliesNew/getReplyNew", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.query<ReplyNewResponse>({
      query: REPLY_NEW_ITEM,
      variables: payload,
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const hireNewReply = createAsyncThunk<
  HireNewReplyResponse,
  MutationHireNewReplyArgs,
  AsyncThunkConfig
>("repliesNew/hireNewReply", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate({
      mutation: HIRE_NEW_REPLY,
      variables: payload,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const changeStatusNewReply = createAsyncThunk<
  ChangeStatusNewReplyResponse,
  MutationChangeStatusNewReplyArgs,
  AsyncThunkConfig
>("repliesNew/changeStatusNewReply", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate({
      mutation: CHANGE_STATUS_NEW_REPLY,
      variables: payload,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});

export const transferNewReplyList = createAsyncThunk<
  BoolSuccess,
  MutationTransferNewReplyListArgs,
  AsyncThunkConfig
>("repliesNew/transferNewReplyList", async (payload, { rejectWithValue }) => {
  try {
    const response = await apollo.mutate({
      mutation: TRANSFER_NEW_REPLY_LIST,
      variables: payload,
    });
    return response.data;
  } catch (err) {
    return rejectWithValue(err.message);
  }
});
