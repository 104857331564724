import React from "react";
import { DatePicker } from "antd";
import type { RangePickerProps } from "antd/lib/date-picker";
import cn from "classnames";

import "./styles.scss";

const { RangePicker: AntRangePicker } = DatePicker;

type Props = RangePickerProps & {};

export const RangePicker: React.FC<Props> = ({
  className,
  disabled,
  format,
  ...props
}) => {
  const datePickerClassNames = cn(
    "ui-range-picker",
    {
      "ui-range-picker--disabled": disabled,
    },
    className
  );

  return (
    <AntRangePicker
      className={datePickerClassNames}
      format={format}
      disabled={disabled}
      separator="-"
      {...props}
    />
  );
};
