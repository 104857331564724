import React from "react";
import { DatePicker as AntDatePicker } from "antd";
import { DatePickerProps } from "antd";
import classNames from "classnames";

import "./styles.scss";

type Props = DatePickerProps & {};

const DatePicker: React.FC<Props> = ({
  className,
  disabled,
  format,
  ...props
}) => {
  const datePickerClassNames = classNames(
    "ui-date-picker",
    {
      "ui-date-picker--disabled": disabled,
    },
    className
  );

  return (
    <AntDatePicker
      className={datePickerClassNames}
      format={format}
      disabled={disabled}
      {...props}
    />
  );
};

export default DatePicker;
