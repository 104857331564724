import React from "react";

import { Recommended } from "components/Recommended";
import { ReplyInfo } from "./components/ReplyInfo";
import { ReplyNewClientType, VacancyType } from "graphql/types/types";

import "./styles.scss";

interface Props {
  vacancy: VacancyType;
  reply: ReplyNewClientType;
}

export const ReplyNew: React.FC<Props> = ({ reply, vacancy }) => {
  return (
    <div className="reply-container">
      <ReplyInfo
        className="reply-container-info"
        vacancy={vacancy}
        reply={reply}
      />

      {reply.candidate ? (
        <Recommended
          candidate={reply.candidate}
          vacancyId={vacancy.id}
          mode="reply"
        />
      ) : null}
    </div>
  );
};
