import React from "react";
import { Form } from "antd";
import cn from "classnames";

import Text from "../../Text";

import "./styles.scss";

interface Props {
  label: string;
  className?: string;
  text: React.ReactNode;
  style?: React.CSSProperties;
}

const FormText: React.FC<Props> = ({ label, className, ...props }) => {
  return (
    <Form.Item label={label} className={cn("ui-form-text", className)}>
      <Text {...props} />
    </Form.Item>
  );
};

export default FormText;
