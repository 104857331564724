import React from "react";
import { Form, SelectProps } from "antd";
import cn from "classnames";

import Select from "../../Select";
import { FormError } from "../FormError";
import { FormLabel } from "../FormLabel";

import "./styles.scss";

interface Props extends SelectProps {
  label: string;
  required?: boolean;
  className?: string;
  error?: string;
}

export const FormSelect: React.FC<Props> = ({
  label,
  required,
  className,
  error,
  ...props
}) => {
  return (
    <Form.Item
      label={<FormLabel label={label} required={required} />}
      className={cn("ui-form-select", className)}
      rules={[{ required, message: `Пожалуйста выберите ${label}` }]}
    >
      <Select status={error ? "error" : undefined} {...props} />

      {error && <FormError error={error} />}
    </Form.Item>
  );
};
