export const MESSAGE_CONFIRM =
  "У вас есть несохраненные изменения. Вы уверены, что хотите уйти?";

export const VALIDATION_MESSAGE = {
  MAX_LIMIT:
    "Превышен лимит символов для этого поля с учетом HTML-разметки. Сократите текст.",
  FULL_ADDRESS:
    "Необходимо указать полный адрес (населенный пункт, улица, номер дома",
  NAME: "Необходимо указать название",
  SALARY: "Необходимо указать уровень дохода",
};
