import React from "react";
import { Form, InputProps, InputRef } from "antd";
import cn from "classnames/dedupe";

import Input from "../../Input";
import { FormError } from "../FormError";
import { FormLabel } from "../FormLabel";

import "./styles.scss";

interface Props extends InputProps {
  label: string;
  error?: string;
}

export const FormInput = React.forwardRef<InputRef, Props>(
  ({ className, required, label, error, ...props }, ref) => {
    return (
      <Form.Item
        label={<FormLabel label={label} required={required} />}
        className={cn("ui-form-input", className)}
        rules={[{ required, message: `, введите ${label}` }]}
        required={required}
      >
        <Input
          ref={ref}
          {...props}
          status={Boolean(error) ? "error" : undefined}
        />

        {error && <FormError error={error} />}
      </Form.Item>
    );
  }
);
