import React from "react";
import { Form, DatePickerProps } from "antd";
import cn from "classnames/dedupe";

import DatePicker from "../../DatePicker";
import { FormLabel } from "../FormLabel";
import { FormError } from "../FormError";

import "./styles.scss";

type Props = DatePickerProps & {
  label: string;
  required?: boolean;
  error?: string;
};

const FormDatePicker: React.FC<Props> = ({
  className,
  label,
  error,
  required,
  ...props
}) => {
  return (
    <Form.Item
      label={<FormLabel label={label} required={required} />}
      className={cn("ui-form-date-picker", className)}
    >
      <DatePicker {...props} />
      {error && <FormError error={error} />}
    </Form.Item>
  );
};

export default FormDatePicker;
