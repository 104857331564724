import { PayloadAction, createSlice, combineReducers } from "@reduxjs/toolkit";
import { ItemState } from "services/store/types";
import { VacancyType } from "graphql/types/types";
import { fetchVacancy } from "./actions";
import { getInitialState } from "services/store/helpers";

type VacancyState = ItemState<VacancyType | null>;

const initialState: VacancyState = {
  data: null,
  loading: true,
  error: null,
};

const vacancySlice = createSlice({
  name: "vacancy",
  initialState,
  reducers: {
    decrementPeopleCountOpen(state) {
      if (!state.data) return;
      const { peopleCountOpen } = state.data;
      state.data.peopleCountOpen = Number(peopleCountOpen) - 1;
    },
    setVacancyInWork(state, { payload }: PayloadAction<{ inWork: boolean }>) {
      if (!state.data) return;
      state.data.inWork = payload.inWork;
    },
    setVacancyPeopleCount(
      state,
      {
        payload,
      }: PayloadAction<
        Pick<VacancyType, "peopleCountOpen" | "peopleCountClose">
      >
    ) {
      if (!state.data) return;
      state.data.peopleCountOpen = payload.peopleCountOpen;
      state.data.peopleCountClose = payload.peopleCountClose;
    },
    setVacancy(state, { payload }: PayloadAction<{ vacancy: VacancyType }>) {
      if (!state.data) return;

      state.data = payload.vacancy;
    },
    setVacancyManagersIds(
      state,
      {
        payload,
      }: PayloadAction<{
        managersIds: VacancyType["managersIds"] | null;
        vacancyId: VacancyType["id"];
      }>
    ) {
      if (!state.data) return;

      if (state.data.id === payload.vacancyId) {
        state.data.managersIds = payload.managersIds;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVacancy.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchVacancy.fulfilled, (state, { payload }) => {
        state.data = payload.privateVacancy;
        state.loading = false;
      })
      .addCase(fetchVacancy.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      });
  },
});

const savedVacancySlice = createSlice({
  name: "savedVacancySlice",
  initialState: getInitialState<VacancyType>(),
  reducers: {
    saveVacancy(state, { payload }: PayloadAction<VacancyType>) {
      state.data = payload;
    },
    clearVacancy(state) {
      state.data = null;
    },
  },
});

export const {
  decrementPeopleCountOpen,
  setVacancyInWork,
  setVacancyPeopleCount,
  setVacancy,
  setVacancyManagersIds,
} = vacancySlice.actions;
export const { saveVacancy, clearVacancy } = savedVacancySlice.actions;

export const vacancyReducer = combineReducers({
  currentVacancy: vacancySlice.reducer,
  savedVacancy: savedVacancySlice.reducer,
});
