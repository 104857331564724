import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IVacancy,
  IVacancyComment,
  IVacancyCommentRequestPayload,
  IVacancyHistory,
  IVacancyRequestPayload,
  IVacancyRequestResponse,
  IVacancyState,
  VacancyActivityType,
} from "./types";
import { client as apollo } from "../../../../graphql/apollo";
import {
  AsyncThunkConfig,
  IActivityMeta,
  IActivityRequestPayload,
  IActivitySalary,
  IMeta,
} from "../../types";
import { PRIVATE_VACANCY_LIST } from "../../../../graphql/requests/query/privateVacancyList";
import { RootState } from "../../rootReducer";
import { IDepartment } from "../directory/types";
import { VACANCY } from "../../../../graphql/requests/query/vacancy";
import { normalizedDate } from "../../../../common/helpers/toISOStringWithTimezone";
import axios from "axios";
import { IHistoryUser, IUser } from "../user/types";
import { camelize } from "../../../../common/helpers/keyConverter";
import { fillUserHistoryList, fillUsersHistory } from "../user/action";
import toaster from "components/UI/Notifications/Notification";
import { IRefusal } from "../replies/types";
import {
  CallCenterInfoType,
  CityType,
  ContactsType,
} from "../../../../graphql/types/types";

export const initialState: IVacancyState = {
  privateVacancyList: [],
  privateVacancyMeta: {
    total: 0,
    limit: 10,
    offset: 0,
  },
  currentVacancy: undefined,
  vacancyComments: [],
  isLoadingVacancyActivity: false,
  vacancyCommentsMeta: {
    total: 0,
    page: 0,
    size: 10,
  },
  vacancyActivity: [],
  vacancyActivityMeta: {
    total: 0,
    page: 0,
    size: 10,
  },
  vacancyHistory: [],
  vacancyHistoryMeta: {
    total: 0,
    page: 0,
    size: 10,
  },
};

export const vacanciesModule = createSlice({
  name: "vacancies",
  initialState,
  reducers: {
    setPrivateVacancyList(
      state: IVacancyState,
      { payload }: PayloadAction<IVacancy[]>
    ) {
      state.privateVacancyList = payload;
    },
    setPrivateVacancyMeta(
      state: IVacancyState,
      { payload }: PayloadAction<IMeta>
    ) {
      state.privateVacancyMeta = payload;
    },
    setCurrentVacancy(
      state: IVacancyState,
      { payload }: PayloadAction<IVacancy>
    ) {
      state.currentVacancy = payload;
    },
    setLoadingVacancyActivity(
      state: IVacancyState,
      { payload }: PayloadAction<boolean>
    ) {
      state.isLoadingVacancyActivity = payload;
    },
    setVacancyComments(
      state: IVacancyState,
      { payload }: PayloadAction<IVacancyComment[]>
    ) {
      const list = state.vacancyComments;
      state.vacancyComments = [...list, ...payload];
    },
    setVacancyCommentsMeta(
      state: IVacancyState,
      { payload }: PayloadAction<IActivityMeta>
    ) {
      state.vacancyCommentsMeta = payload;
    },
    clearVacancyComments(state: IVacancyState) {
      state.vacancyComments = [];
      state.vacancyCommentsMeta = {
        total: 0,
        page: 0,
        size: 10,
      };
    },
    setVacancyHistory(
      state: IVacancyState,
      { payload }: PayloadAction<IVacancyHistory[]>
    ) {
      const list = state.vacancyHistory;
      state.vacancyHistory = [...list, ...payload];
    },
    setVacancyHistoryMeta(
      state: IVacancyState,
      { payload }: PayloadAction<IActivityMeta>
    ) {
      state.vacancyHistoryMeta = payload;
    },
    clearVacancyHistory(state: IVacancyState) {
      state.vacancyHistory = [];
      state.vacancyHistoryMeta = {
        total: 0,
        page: 0,
        size: 10,
      };
    },
    setVacancyActivity(
      state: IVacancyState,
      { payload }: PayloadAction<VacancyActivityType[]>
    ) {
      const list = state.vacancyActivity;
      state.vacancyActivity = [...list, ...payload];
    },
    setVacancyActivityMeta(
      state: IVacancyState,
      { payload }: PayloadAction<IActivityMeta>
    ) {
      state.vacancyActivityMeta = payload;
    },
    clearVacancyActivity(state: IVacancyState) {
      state.vacancyActivity = [];
      state.vacancyActivityMeta = {
        total: 0,
        page: 0,
        size: 10,
      };
    },
  },
});
// * mutations
export const {
  setPrivateVacancyList,
  setPrivateVacancyMeta,
  setCurrentVacancy,
  setLoadingVacancyActivity,
  setVacancyComments,
  setVacancyCommentsMeta,
  clearVacancyComments,
  setVacancyHistory,
  setVacancyHistoryMeta,
  clearVacancyHistory,
  setVacancyActivity,
  setVacancyActivityMeta,
  clearVacancyActivity,
} = vacanciesModule.actions;

// * getters
export const privateVacancyList = (state: RootState) =>
  state.vacancies.privateVacancyList;
export const privateVacancyMeta = (state: RootState) =>
  state.vacancies.privateVacancyMeta;
export const currentVacancySelector = (state: RootState) =>
  state.vacancies.currentVacancy;

export const isLoadingVacancyActivity = (state: RootState) =>
  state.vacancies.isLoadingVacancyActivity;

export const currentVacancyComments = (state: RootState) => {
  const { historyUserList } = state.user.item;
  const { vacancyComments } = state.vacancies;
  return vacancyComments.map((item: IVacancyComment) => ({
    ...item,
    manager: item?.userId ? historyUserList?.[item?.userId] : undefined,
    createdAt: normalizedDate(item.createdAt),
  }));
};
export const vacancyCommentsMeta = (state: RootState) =>
  state.vacancies.vacancyCommentsMeta;

export const currentVacancyHistory = (state: RootState) => {
  const { historyUserList } = state.user.item;
  const { vacancyHistory } = state.vacancies;

  const data = vacancyHistory.map((item) => {
    const el = {
      ...item,
      manager: item?.userId ? (historyUserList?.[item?.userId] as IUser) : 0,
      createdAt: normalizedDate(item.createdAt),
    };
    if (item?.field === "owner_id" || item?.field === "manager_id") {
      el.newManager = historyUserList?.[item?.newValue as number] as IUser;
      el.oldManager = historyUserList?.[item?.oldValue as number] as IUser;
    }
    return el;
  });
  return data as IVacancyHistory[];
};

export const vacancyHistoryMeta = (state: RootState) =>
  state.vacancies.vacancyHistoryMeta;
export const vacancyActivityMeta = (state: RootState) =>
  state.vacancies.vacancyActivityMeta;

export const currentVacancyActivity = (state: RootState) => {
  const { historyUserList } = state.user.item;
  const { vacancyActivity } = state.vacancies;
  const data = vacancyActivity.map((item: VacancyActivityType) => {
    const el = {
      ...item,
      manager: item?.userId ? (historyUserList?.[item?.userId] as IUser) : 0,
      createdAt: normalizedDate(item.createdAt),
      newManager: undefined,
      oldManager: undefined,
    } as VacancyActivityType;
    if (
      item.modelType === "event" &&
      (item?.field === "owner_id" || item?.field === "manager_id")
    ) {
      if ("newManager" in el) {
        el.newManager = historyUserList?.[item?.newValue as number] as IUser;
      }
      if ("oldManager" in el) {
        el.oldManager = historyUserList?.[item?.oldValue as number] as IUser;
      }
    }
    return el;
  });
  return data.sort(
    (a: VacancyActivityType, b: VacancyActivityType) =>
      new Date(b.createdAt)?.valueOf() - new Date(a.createdAt)?.valueOf()
  );
};

// * actions
export const getPrivateVacancyList = createAsyncThunk<
  void,
  IVacancyRequestPayload,
  AsyncThunkConfig
>("vacancy/privateVacancyList", async (payload, thunkAPI) => {
  try {
    const res = await apollo.query<IVacancyRequestResponse>({
      query: PRIVATE_VACANCY_LIST,
      variables: {
        pagination: payload.pagination,
        filter: payload.filter,
      },
    });
    if (res?.data?.privateVacancyList?.data) {
      const departmentList = thunkAPI.getState().directory?.departments || [];
      const privateVacancyList = res?.data?.privateVacancyList?.data.map(
        (item) => ({
          ...item,
          manager: {
            ...item.manager,
            department: departmentList.find(
              (el) =>
                el.id ===
                parseInt(item.manager?.departmentId?.toString() || "", 10)
            ),
          },
        })
      );
      thunkAPI.dispatch(
        setPrivateVacancyList(privateVacancyList as IVacancy[])
      );
    }
    if (res?.data?.privateVacancyList?.meta) {
      const privateVacancyMeta = res?.data?.privateVacancyList?.meta;
      thunkAPI.dispatch(setPrivateVacancyMeta(privateVacancyMeta));
    }
  } catch (err) {
    console.error(err);
    thunkAPI.dispatch(setPrivateVacancyList([]));
  }
});

export const getVacancyById = createAsyncThunk<
  IVacancy,
  number,
  AsyncThunkConfig
>("vacancy/vacancyById", async (payload, thunkAPI) => {
  try {
    const res = await apollo.query({
      query: VACANCY,
      variables: {
        id: payload,
      },
    });
    if (res?.data?.privateVacancy) {
      const departmentList = thunkAPI.getState()?.directory?.departments || [];
      const data = res.data.privateVacancy;
      if (data?.manager?.department) {
        data.manager.department = departmentList.find(
          (el: IDepartment) =>
            el.id === parseInt(data.manager?.departmentId?.toString() || "", 10)
        );
      }

      thunkAPI.dispatch(setCurrentVacancy(data as IVacancy));
      return data;
    }
  } catch (err) {
    console.error(err);
    thunkAPI.dispatch(setPrivateVacancyList([]));
  }
});

export const createVacancyComment = createAsyncThunk<
  void,
  IVacancyCommentRequestPayload,
  AsyncThunkConfig
>("vacancy/createComment", async (payload, thunkAPI) => {
  const { userId, vacancyId, content } = payload;
  try {
    thunkAPI.dispatch(setLoadingVacancyActivity(true));
    const activityApiUrl = process.env.REACT_APP_HISTORY_API_URL;
    await axios.post(`${activityApiUrl}/comment/vacancy/`, {
      user_id: userId,
      content: content,
      vacancy_id: vacancyId,
    });
  } catch (err) {
    console.error(`vacancy id ${vacancyId}`);
    console.error(err);
    toaster.error({ title: err?.message || "Ошибка отправки комментария" });
  } finally {
    thunkAPI.dispatch(setLoadingVacancyActivity(false));
  }
});

export const getCurrentVacancyComments = createAsyncThunk<
  void,
  IActivityRequestPayload,
  AsyncThunkConfig
>("vacancy/getComments", async (payload, thunkAPI) => {
  try {
    thunkAPI.dispatch(setLoadingVacancyActivity(true));

    const { id, page } = payload;
    const nextPage = page + 1;
    const size = 10;
    const activityApiUrl = process.env.REACT_APP_HISTORY_API_URL;
    const res = await axios.get(
      `${activityApiUrl}/comment/vacancy/${id}?page=${nextPage}&size=${size}`
    );

    if (res?.data?.items?.length) {
      const historyUserCollection: IHistoryUser =
        thunkAPI.getState()?.user?.item.historyUserList;
      const loadedUserIdList: string[] = Object.keys(historyUserCollection);
      const payloadIdList: string[] = [];

      const data = camelize(res.data.items) as IVacancyComment[];
      data.map((item) => {
        const userId = item?.userId;
        if (
          userId &&
          !loadedUserIdList?.includes(String(userId)) &&
          !payloadIdList?.includes(String(userId))
        ) {
          payloadIdList.push(String(userId));
        }
        return null;
      });
      if (payloadIdList?.length) {
        thunkAPI.dispatch(fillUserHistoryList(payloadIdList));
      }
      const meta = {
        page: res?.data?.page,
        size: res?.data?.size,
        total: res?.data?.total,
      };

      thunkAPI.dispatch(setVacancyCommentsMeta(meta));
      thunkAPI.dispatch(setVacancyComments(data));
    }
  } catch (err) {
    console.error(`vacancy id ${payload}`);
    toaster.error({ title: err?.message || "Ошибка загрузки комментариев" });
  } finally {
    thunkAPI.dispatch(setLoadingVacancyActivity(false));
  }
});

export const getCurrentVacancyHistory = createAsyncThunk<
  void,
  IActivityRequestPayload,
  AsyncThunkConfig
>("vacancy/getHistory", async (payload, thunkAPI) => {
  try {
    thunkAPI.dispatch(setLoadingVacancyActivity(true));

    const { id, page } = payload;
    const nextPage = page + 1;
    const size = 10;
    const activityApiUrl = process.env.REACT_APP_HISTORY_API_URL;
    const res = await axios.get(
      `${activityApiUrl}/history/vacancy/${id}?page=${nextPage}&size=${size}`
    );

    if (res?.data?.items?.length) {
      const preparedData: IVacancyHistory[] = camelize(res?.data.items).sort(
        (a: IVacancyHistory, b: IVacancyHistory) =>
          new Date(b.createdAt)?.valueOf() - new Date(a.createdAt)?.valueOf()
      );
      const data = preparedData.map((item) => {
        const el = item;
        if (item.field === "manager_id" || item.field === "owner_id") {
          const historyUserCollection =
            thunkAPI.getState()?.user?.item.historyUserList;

          if (!historyUserCollection?.[item?.newValue as number]) {
            const payload = [item.newValue];
            thunkAPI.dispatch(fillUserHistoryList(payload));
          } else {
            el.newManager = historyUserCollection?.[
              item?.newValue as number
            ] as IUser;
          }
          if (!historyUserCollection?.[item?.oldValue as number]) {
            const payload = [item.oldValue];
            thunkAPI.dispatch(fillUserHistoryList(payload));
          } else {
            el.oldManager = historyUserCollection?.[
              item?.oldValue as number
            ] as IUser;
          }
        }
        if (
          [
            "contacts",
            "refusal",
            "salary",
            "address",
            "call_center_info",
          ].includes(item.field)
        ) {
          if (item?.newValue) {
            const newValue = JSON.parse(item.newValue as string);
            el.newValue = camelize(newValue) as
              | ContactsType
              | IUser
              | IActivitySalary
              | CallCenterInfoType
              | IRefusal;
          }
          if (item.oldValue) {
            const oldValue = JSON.parse(item.oldValue as string);
            el.oldValue = camelize(oldValue) as
              | ContactsType
              | IUser
              | IActivitySalary
              | CallCenterInfoType
              | IRefusal;
          }
        }
        if (item.field === "city") {
          const cityDict = thunkAPI.getState()?.directory?.cities;
          const oldValue = item.oldValue as string;
          const newValue = item.newValue as string;
          const oldCity = cityDict.find(
            (item: CityType) => item.cityFiasId === oldValue
          );
          const newCity = cityDict.find(
            (item: CityType) => item.cityFiasId === newValue
          );
          el.oldValue = `${oldCity?.city || ""} ${
            oldCity?.regionWithType || ""
          }`;
          el.newValue = `${newCity?.city || ""} ${
            newCity?.regionWithType || ""
          }`;
        }
        return el;
      });
      const meta = {
        page: res?.data?.page,
        size: res?.data?.size,
        total: res?.data?.total,
      };
      thunkAPI.dispatch(setVacancyHistoryMeta(meta));
      thunkAPI.dispatch(setVacancyHistory(data));
      thunkAPI.dispatch(fillUsersHistory());
    } else {
      console.error("activity not found ");
      thunkAPI.dispatch(setVacancyHistory([]));
    }
  } catch (err) {
    console.error(`vacancy id ${payload}`);
    console.error(err);
    toaster.error({
      title: err?.message || "Ошибка загрузки истории изменения вакснии",
    });
  } finally {
    thunkAPI.dispatch(setLoadingVacancyActivity(false));
  }
});

export const getVacancyActivity = createAsyncThunk<
  void,
  IActivityRequestPayload,
  AsyncThunkConfig
>("vacancy/getVacancyActivity", async (payload, thunkAPI) => {
  try {
    thunkAPI.dispatch(setLoadingVacancyActivity(true));

    const { id, page } = payload;
    const nextPage = page + 1;
    const size = 10;
    const activityApiUrl = process.env.REACT_APP_HISTORY_API_URL;
    const res = await axios.get(
      `${activityApiUrl}/vacancy/${id}?page=${nextPage}&size=${size}`
    );
    if (res?.data?.items?.length) {
      const allActivity = camelize(res.data.items) || [];
      //* заполняем пользователей
      const historyUserCollection =
        thunkAPI.getState()?.user?.item.historyUserList;
      const loadedUserIdList = Object.keys(historyUserCollection);
      const payloadIdList: string[] = [];
      allActivity.map((item: VacancyActivityType) => {
        const userId = item?.userId;
        if (
          userId &&
          !loadedUserIdList?.includes(String(userId)) &&
          !payloadIdList?.includes(String(userId))
        ) {
          payloadIdList.push(String(userId));
        }
        return null;
      });
      if (payloadIdList?.length) {
        thunkAPI.dispatch(fillUserHistoryList(payloadIdList));
      }

      const commentList = allActivity.filter(
        (item: VacancyActivityType) => item.modelType === "comment"
      );
      const historyList = allActivity.filter(
        (item: VacancyActivityType) => item.modelType === "event"
      );

      const preparedHistoryList = historyList.map((item: IVacancyHistory) => {
        const el = item as IVacancyHistory;
        if (item.field === "manager_id" || item.field === "owner_id") {
          const historyUserCollection: IHistoryUser =
            thunkAPI.getState()?.user?.item.historyUserList;
          if (!historyUserCollection?.[item?.newValue as number]) {
            const payload = [item.newValue];
            thunkAPI.dispatch(fillUserHistoryList(payload));
          } else {
            el.newManager = historyUserCollection?.[
              item?.newValue as number
            ] as IUser;
          }
          if (!historyUserCollection?.[item?.oldValue as number]) {
            const payload = [item.oldValue];
            thunkAPI.dispatch(fillUserHistoryList(payload));
          } else {
            el.oldManager = historyUserCollection?.[
              item?.oldValue as number
            ] as IUser;
          }
        }
        if (
          [
            "contacts",
            "refusal",
            "salary",
            "address",
            "call_center_info",
          ].includes(item.field)
        ) {
          if (item?.newValue) {
            const newValue = JSON.parse(item.newValue as string);
            el.newValue = camelize(newValue) as
              | ContactsType
              | IUser
              | IActivitySalary
              | CallCenterInfoType
              | IRefusal;
          }
          if (item.oldValue) {
            const oldValue = JSON.parse(item.oldValue as string);
            el.oldValue = camelize(oldValue) as
              | ContactsType
              | IUser
              | IActivitySalary
              | CallCenterInfoType
              | IRefusal;
          }
        }
        if (item.field === "city") {
          const cityDict = thunkAPI.getState()?.directory?.cities;
          const oldValue = item.oldValue as string;
          const newValue = item.newValue as string;
          const oldCity = cityDict.find(
            (item: CityType) => item.cityFiasId === oldValue
          );
          const newCity = cityDict.find(
            (item: CityType) => item.cityFiasId === newValue
          );
          el.oldValue = `${oldCity?.city || ""} ${
            oldCity?.regionWithType || ""
          }`;
          el.newValue = `${newCity?.city || ""} ${
            newCity?.regionWithType || ""
          }`;
        }
        return el;
      });

      const meta = {
        page: res?.data?.page,
        size: res?.data?.size,
        total: res?.data?.total,
      };
      thunkAPI.dispatch(setVacancyActivityMeta(meta));
      const allActivityList = [...commentList, ...preparedHistoryList];

      thunkAPI.dispatch(setVacancyActivity(allActivityList));
    }
  } catch (err) {
    console.error(`vacancy id ${payload}`);
    toaster.error({ title: err?.message || "Ошибка загрузки активности" });
  } finally {
    thunkAPI.dispatch(setLoadingVacancyActivity(false));
  }
});

export default vacanciesModule.reducer;
