import React from "react";
import { omit } from "lodash";
import {
  CallCenterInfoType,
  PositionsRelationsType,
  PositionType,
  UserType,
  VacancyInputType,
  VacancyNationalityItemType,
  VacancyRelevance,
  VacancyStatus,
  VacancyType,
} from "graphql/types/types";
import { ModalMode, RabotutState } from "./types";
import { PropsTags, Tag } from "components/UI/Tag";
import { Notification, Type } from "components/UI/Notifications/Notification";
import { VACANCY_RELEVANCE, VACANCY_STATUS_TYPE } from "common/const/status";
import { InfoItem } from "../InfoItem";
import {
  transformToAddressInputType,
  transformToDaDataAddressSuggestion,
} from "common/helpers/transformDaData";
import { getContacts, getSalary } from "../helpers";

type InitialStateVacancyForm = {
  vacancy: VacancyType;
  positionRelations?: PositionsRelationsType;
};

const findNameById = (id, positions) =>
  positions.find((position) => position.id === id)?.name;

export const getInitialState = ({
  vacancy,
  positionRelations,
}: InitialStateVacancyForm) => ({
  name: vacancy.name ?? "",
  employmentId: vacancy.employment?.id ?? 0,
  salaryFrom: vacancy.salary.from ? String(vacancy.salary.from) : "",
  salaryTo: vacancy.salary.to ? String(vacancy.salary.to) : "",
  address: transformToDaDataAddressSuggestion(vacancy.address),
  tagKey: vacancy.tag?.key ?? null,
  relevance: vacancy.relevance === VacancyRelevance.Relevant,
  description: vacancy.description ?? "",
  shortDescription: vacancy.shortDescription ?? "",
  terms: vacancy.terms ?? "",
  claims: vacancy.claims ?? "",
  managerId: vacancy.manager.id ?? "",
  employerId: vacancy.employer?.id ?? 0,
  callCenterInfoDesc: vacancy.callCenterInfo.description ?? "",
  callCenterInfoProject: vacancy.callCenterInfo.project ?? "",
  showContacts: vacancy.showContacts ?? true,
  positionId: vacancy.positionId ?? undefined,
  platformTags: vacancy.platformTags ?? positionRelations?.avitoTags ?? "",

  // поля которые не меняются в форме, но мы их шлем на бэк
  age: vacancy.age,
  additionalAvito: vacancy.additionalAvito,
  additionalHh: vacancy.additionalHh,
  metro: vacancy.metro,
  city: vacancy.city,
  kladrId: vacancy.kladrId,
  region: vacancy.region,
  subdivision: vacancy.subdivision,
  counterparty: vacancy.counterparty,
  route: vacancy.route,
  priority: vacancy.priority,
  tradePoint: vacancy.tradePoint,
  shiftStart: vacancy.shiftStart,
  shiftEnd: vacancy.shiftEnd,
  peopleCount: vacancy.peopleCount,
  schedule: vacancy.schedule,
  reason: vacancy.reason,
  responsiblePerson: vacancy.responsiblePerson,
  phonePresence: vacancy.phonePresence,
  medicalBook: vacancy.medicalBook,
  citizenshipId: vacancy.citizenship?.id,
  rate: vacancy.rate,
});

export const getPayload = (
  formState: RabotutState,
  positions: PositionType[],
  managerList: UserType[],
  callCenterInfo: CallCenterInfoType,
  nationalities: VacancyNationalityItemType[]
) => {
  const {
    salaryFrom,
    salaryTo,
    address,
    positionId,
    relevance,
    managerId,
    callCenterInfoDesc,
    callCenterInfoProject,
  } = formState;

  const payload: VacancyInputType = {
    ...omit(formState, [
      "salaryFrom",
      "salaryTo",
      "address",
      "relevance",
      "callCenterInfoProject",
      "callCenterInfoDesc",
      "managerId",
    ]),
    salary: getSalary(salaryFrom, salaryTo),
    contacts: getContacts(managerId, managerList),
    address: transformToAddressInputType(address),
    name: findNameById(positionId, positions),
    relevance: relevance
      ? VacancyRelevance.Relevant
      : VacancyRelevance.NotRelevant,
    callCenterInfo: {
      // поля которые не меняются в форме, но мы их шлем на бэк
      ...callCenterInfo,
      description: callCenterInfoDesc,
      project: callCenterInfoProject,
    },
    nationalityId: getNationalities(nationalities),
  };
  return payload;
};

export const getTitleByMode = (
  vacancyName: string
): Record<ModalMode, string> => ({
  publication: "Опубликовать вакансию",
  editing: vacancyName,
});

export const tagColorByStatus: Record<VacancyStatus, PropsTags["color"]> = {
  [VacancyStatus.Archived]: "default",
  [VacancyStatus.InWork]: "default",
  [VacancyStatus.Draft]: "default",
  [VacancyStatus.Moderation]: "orange",
  [VacancyStatus.Published]: "green",
  [VacancyStatus.Refused]: "red",
};

export const tagColorByRelevance: Record<VacancyRelevance, PropsTags["color"]> =
  {
    [VacancyRelevance.Relevant]: "green",
    [VacancyRelevance.NotRelevant]: "red",
  };

const getNotificationComponent = (title: string, type: Type) => (
  <Notification
    type={type}
    title={title}
    className="rabotut-form-notification"
  />
);

export const renderNotificationByMode = (
  isModeration: boolean,
  publishedOnRabotut = false
): Record<ModalMode, React.ReactElement | undefined> => ({
  publication: publishedOnRabotut
    ? getNotificationComponent(
        "Внимание, такая вакансия по данному адресу уже опубликована",
        "error"
      )
    : undefined,
  editing: isModeration
    ? getNotificationComponent(
        "Вакансия находится на проверке модератором перед публикацией",
        "warning"
      )
    : undefined,
});

export const getInfoItems = (
  status: VacancyStatus,
  relevance: VacancyRelevance
) => [
  {
    name: "Статус:",
    value: (
      <Tag color={tagColorByStatus[status]}>{VACANCY_STATUS_TYPE[status]}</Tag>
    ),
  },
  {
    name: "Актуальность вакансии:",
    value: (
      <Tag color={tagColorByRelevance[relevance]}>
        {VACANCY_RELEVANCE[relevance]}
      </Tag>
    ),
    className: "rabotut-form-info-tag",
  },
];

export const renderInfoItems = (
  status: VacancyStatus | null | undefined,
  relevance: VacancyRelevance | null | undefined
) =>
  status && relevance
    ? getInfoItems(status, relevance).map(({ name, value, className }) => (
        <InfoItem name={name} value={value} className={className} />
      ))
    : undefined;

export const buttonNameByMode = (
  disabled: boolean,
  itemsLength: number
): Record<ModalMode, string> => ({
  publication: itemsLength === 1 ? "Опубликовать" : "Опубликовать и продолжить",
  editing: disabled ? "Редактировать" : "Сохранить",
});

export const getNationalities = (nationalities: VacancyNationalityItemType[]) =>
  nationalities.reduce<number[]>(
    (acc, { nationality }) =>
      nationality?.id ? [...acc, nationality.id] : acc,
    []
  );
