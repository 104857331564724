import { useCallback } from "react";
import { useAppDispatch } from "services/store/store";
import { loadUsers } from "services/store/modules/user/action";
import { UserFilterType, UserRole } from "graphql/types/types";

const LIMIT = 700;

export const useFetchUsers = (filter: UserFilterType = {}, limit = LIMIT) => {
  const dispatch = useAppDispatch();

  const fetchUsers = useCallback(
    (role?: UserRole) => {
      return dispatch(
        loadUsers({
          filter: role ? { role } : filter,
          pagination: { limit },
        })
      );
    },
    [dispatch, filter, limit]
  );

  return { fetchUsers };
};
