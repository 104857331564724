import * as yup from "yup";

import { VALIDATION_MESSAGE } from "common/const/messages";

export const schema = yup
  .object({
    title: yup.string().required(VALIDATION_MESSAGE.NAME),
    employment: yup.string().required("Необходимо указать тип занятости"),
    experience: yup.string().required("Необходимо указать опыт работы"),
    schedule: yup.string().required("Необходимо указать график работы"),
    managerId: yup.number().required("Необходимо указать менеджера вакансии"),
    address: yup.object().shape({
      value: yup.string().required("Необходимо указать место работы"),
    }),
  })
  .required();
